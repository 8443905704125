export default {
    methods: {
        allowDrop(draggingNode, dropNode, type) {
            // console.log('draggingNode', draggingNode)
            // 最顶层的不能放置，只能在最顶层之内放置
            if (dropNode.data.id === this.topItemId) {
                return type === 'inner';
            } else {
                return true;
            }
        },
        allowDrag(draggingNode) {
            // 最顶层的那个不能拖动
            return draggingNode.data.id !== this.topItemId
        },
        handleDrop(draggingNode, dropNode, dropType) {
            // 拖拽完成并成功
            // console.log('draggingNode', draggingNode)
            // console.log('dropNode', dropNode)
            // console.log('dropType', dropType)
            if (dropType === 'inner') {
                dropNode.childNodes.forEach((item, index) => {
                    let node = {
                        parentId: dropNode.data.id,
                        name: item.data.name,
                        seq: index
                    }
                    this.putEditItem(item.data.id, node);
                });
            } else {
                dropNode.parent.childNodes.forEach((item, index) => {
                    let node = {
                        parentId: dropNode.parent.data.id,
                        name: item.data.name,
                        seq: index
                    }
                    this.putEditItem(item.data.id, node);
                });
            }
            



            // console.log('提交的node',curretnNodeId,  node)
            // this.putEditItem(curretnNodeId, node);
        },
    }
}